// Base
@import 'abstracts/variables';
@import 'abstracts/mixins';
@import 'abstracts/helpers';
@import 'abstracts/animations';
// Vendors
@import 'vendors/bootstrap/overwrites.scss';
@import 'bootstrap/scss/bootstrap.scss';
@import 'vendors/sb-admin/sb-admin';
@import '../../node_modules/css-toggle-switch/dist/toggle-switch-rem.css';
@import 'vendors/owfont-master/css/owfont-regular.css';
// Themes
@import 'themes/sb-admin-override';

// Pages
@import 'pages/error-404';
@import 'pages/login';

// Components
@import 'components/badge';
@import 'components/callout';
@import 'components/card';
@import 'components/icons';
@import 'components/light-switch';
@import 'components/loading';
@import 'components/markdown';
@import 'components/scan';
@import 'components/scanner';
@import 'components/switch';
