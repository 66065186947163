.switch {
  display: block;
  position: relative;
 	width: 35px;
  height: 50px;
  margin: 60px 15px;

  border-radius: 10px;
  background: #e6e3da;
  background: linear-gradient(#e6e3da, #fff);
  border: 1px solid rgba(0,0,0,0.1);

  box-shadow: inset 0 7px 0 #fdfdfd, 0 2px 3px rgba(170, 160, 140,.3);
  cursor: pointer;

  &:before {
    content: "";
    position: absolute;
    top: -10px; bottom: -10px;
    left: -5px; right: -5px;
    z-index: -1;

    background: #f2f1ed;
    border-radius: inherit;
    box-shadow: 0 1px 1px rgba(#aea391,0.2), 0 3px 3px rgba(170, 160, 140, 0.4), inset 0 1px 0 rgba(255,255,255,0.8), 0 0 5px rgba(170, 160, 140, 0.5);
  }
  &:after {
    content: "";
    position:absolute;
    width: 30px;
    height: 40px;
    border-radius: 15%;
    z-index: -1;
    left: 7px;
    top: 1px;
    background: linear-gradient(160deg, rgba(170, 160, 140, 0.7), rgba(170, 160, 140, 0));
    background: -webkit-linear-gradient(290deg, rgba(170, 160, 140, 0.75), rgba(170, 160, 140, 0));
    filter: blur(1px);
  }

  .text {
    position: absolute;
    left: 70px;
    min-width: 150px;
  }
  .indicator {
    position: absolute;
    top: -25px;
    left: 5px;
    width: 25px;
    height: 8px;
    box-shadow: 0 0 8px #000 inset;
    border: 1px solid rgba(255,255,255,0.1);
    border-radius: 15px;
    transition: 0.2s;
    &:before {
      content: '';
      display: inline-block;
      margin-top: 11px;
      width: 10px;
      height: 5px;
      border-radius: 10px;
      transition: 0.5s;
      position: absolute;
      left: 7px;
      top: -10px;
    }
  }
}

.light-switch {
 	clip: rect(0 0 0 0);
  position: absolute;
  visibility: hidden;
  &:checked ~ .switch {
    background: linear-gradient(#f7f6f4, #fff);
    box-shadow:
    inset 0 -5px 0 #dbd3c8,
    0 6px 5px rgba(170, 160, 140, 0.75),
    3px 16px 5px rgba(170,160,140, 0.3);
    border-bottom: none;
    &:after {
      display: none;
    }
    .text {
      font-weight: bold;
    }
    .indicator {
      &:before {
        box-shadow: 0 0 5px 3px rgba(186, 252, 88, .95);
        width: 10px;
        background: #F0F9E3;
        transition: 0.1s;
      }
    }
  }
}