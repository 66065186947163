.scanner {
  &.card-scanner {
    position: absolute;
    top: 10px;
    right: 10px;;
  }
	.one, .two, .three, .four, .five, .six, .seven, .eight, .nine, .ten {
		width: 12px;
		height: 8px;
		background: darken($greenMeadow, 20%);
		margin: 0 1px;
		display: inline-block;
		box-shadow: 0 0 3px darken($greenMeadow, 20%), inset 0 0 1px rgba(255,255,255, 0.5);
		border-radius: 2px;
		animation-duration: 1s;
		animation-iteration-count: infinite;
		animation-direction: alternate;
	}
}
.one {
   animation-name: first ;
}
.two {
  animation-name: second ;
}
.three {
  animation-name: third ;
}
.four {
  animation-name: fourth ;
}
.five {
  animation-name: fifth ;
}
@keyframes first {
  0%   {opacity:0.3;}
  17%  {opacity:0.8;}
  100% {opacity:0.3;}
}
@keyframes second {
  0%   {opacity:0.3;}
  32%   {opacity:0.8;}
  100% {opacity:0.3;}
}
@keyframes third {
  0%   {opacity:0.3;}
  47%   {opacity:0.8;}
  100% {opacity:0.3;}
}
@keyframes fourth {
  0%   {opacity:0.3;}
  62%   {opacity:0.8;}
  100% {opacity:0.3;}
}
@keyframes fifth {
  0%   {opacity:0.3;}
  77%   {opacity:0.8;}
  100% {opacity:0.3;}
}

.scanner-dot {
  width: 100%;
  height: 2px;
  position: absolute;
  top: -2px;
  right: 0;
  .dot {
		border-radius: 50%;
    animation: run 3.5s linear infinite alternate;
    background: $red;
    box-shadow: 0 0 5px darken($red, 20%), inset 0 0 2px rgba(255,255,255, 0.5);
    display: inline-block;
    height: 3px;
    position: absolute;
    left: 0;
    width: 3px;
  }
}

@keyframes run {
  0% { left: 0; opacity: 0.2; }
  50%{ left : 100%; opacity: 1; }
  100%{ left: 0; opacity: .2; }
}

