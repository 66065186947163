footer.sticky-footer {
  position: absolute;
  right: 0;
  bottom: 0;

  width: 100%;
  height: $sticky-footer-height;

  background-color: $gray-200;

  line-height: 55px;
  @media (min-width: 992px) {
    width: calc(100% - #{$sidenav-base-width});
  }
}

body.side-nav-closed {
  @media (min-width: 992px) {
    footer.sticky-footer {
      width: calc(100% - #{$sidenav-collapsed-width});
    }
  }
}
