.position-relative {
  position: relative !important;
}

.zIndex1 {
  z-index: 1;
}

.outline-0 {
  outline: 0 !important;
}
