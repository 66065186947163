body {
  font-family: $text-font-stack !important;
}
h1,
.h1 {
  font-size: 2.1em; /* equal to 36px */
  font-family: $header-font-stack;
}
h2,
.h2 {
  font-size: 1.9em; /* equal to 30px */
  font-family: $header-font-stack;
}
h3,
.h3 {
  font-size: 1.8em; /* equal to 24px */
  font-family: $header-font-stack;
}
h4,
.h4 {
  font-size: 1.7em; /* equal to 18px */
  font-family: $header-font-stack;
}
h5,
.h5 {
  font-size: 1.4em; /* equal to 14px */
  font-family: $header-font-stack;
}
h6,
.h6 {
  font-size: 1.2em; /* equal to 12px */
  font-family: $header-font-stack;
}

.content-wrapper {
  background: $wrapperBg;
  padding-top: 0;
  > .container-fluid {
    padding-top: 15px;
    margin-top: 56px;
    margin-bottom: 56px;
  }
}

nav {
  .dropdown-item {
    white-space: normal;
    min-width: 300px;
    position: relative;
    padding: 0.5rem 2.5rem 0.25rem 1.5rem;
    &.active,
    &:active {
      background-color: $greenMeadow;
      .text-muted {
        color: #d0d7e6 !important;
      }
    }
    .close {
      position: absolute;
      top: 0px;
      right: 15px;
    }
  }
  ul {
    &.navbar-nav {
      .nav-item {
        &.active {
          a {
            color: $white !important;
            font-weight: bold;
          }
        }
        &.user {
          cursor: default;
          .nav-link {
            padding: 0 !important;
          }
          .profile-img {
            padding: 10px 30px;
          }
          .name {
            color: $white;
            width: 100%;
            padding: 6px 30px;
            background: rgba(0, 0, 0, 0.5);
            display: block;
            font-size: 0.8rem;
          }
        }
      }
    }
  }
  .navbar-toggler {
    z-index: 10;
  }
}

#mainNav .navbar-collapse {
  transition: all 400ms ease-in;
  &.collapse:not(.show) {
    max-height: 0;
    display: block;
  }
  &.show {
    max-height: 75vh;
  }
  .nav-link {
    &.button {
      background: no-repeat;
      border: none;
    }
    > .fas {
      width: 30px;
    }
    &.no-items {
      &:after {
        content: '' !important;
      }
    }
  }
}

#mainNav.navbar-dark .navbar-collapse .navbar-sidenav > .nav-item > .nav-link {
  color: rgba(255, 255, 255, 0.5);
}

@media (min-width: 992px) {
  .content-wrapper {
    margin-left: 250px;
  }
  nav {
    ul.navbar-nav {
      .nav-item.user {
        .profile-img {
          padding: 25px 30px;
        }
      }
    }
  }
}
