.st0 {
  fill: none;
}
.st1 {
  fill: #504e55;
}
.st2 {
  fill: #f39e72;
}
.st3 {
  fill: #ffffff;
}
.st4 {
  opacity: 0.24;
}
.st5 {
  fill: #77574e;
}
.st6 {
  fill: #fbd68d;
}
.st7 {
  fill: #ececec;
}
.st8 {
  fill: #f4a89c;
}
.st9 {
  fill: #cfc9e5;
}
.st10 {
  opacity: 0.28;
}
.st11 {
  opacity: 0.25;
}
.st12 {
  fill: #6f635c;
}
.st13 {
  fill: #dae7be;
}
.st14 {
  fill: #ffe0a6;
}
.st15 {
  fill: #5f5e60;
}
.st16 {
  fill: #cfe1af;
}
.st17 {
  fill: #ebe9f5;
}
.st18 {
  fill: #53515a;
}
.st19 {
  opacity: 0.42;
}
.st20 {
  fill: #53515b;
}

.particle-error,
.permission_denied,
.particles-js {
  width: 100%;
  height: 100%;
}
.particles-js {
  position: fixed !important;
  opacity: 0.23;
}

.permission_denied {
  background: $gray-900 !important;
  // min-height: calc(100vh - 56px - 56px);
  min-height: 100vh;
  a {
    text-decoration: none;
  }
  h1 {
    text-align: center;
    color: #fff;
    font-size: 100px;
    margin-bottom: 0px;
    font-weight: 800;
  }
  h3 {
    text-align: center;
    color: #fff;
    font-size: 19px;
    line-height: 23px;
    max-width: 330px;
    margin: 0px auto 30px auto;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    span {
      position: relative;
      width: 65px;
      display: inline-block;
      &:after {
        content: '';
        border-bottom: 3px solid #ffbb39;
        position: absolute;
        left: 0;
        top: 43%;
        width: 100%;
      }
    }
  }
  .stars {
    animation: sparkle 1.6s infinite ease-in-out alternate;
  }
  + .sticky-footer {
    background: $gray-900 !important;
    color: white;
  }
}

.denied__wrapper {
  max-width: 390px;
  width: 100%;
  height: 390px;
  display: block;
  margin: 0 auto;
  position: relative;
}

.denied__link {
  background: none;
  color: #fff;
  padding: 12px 0px 10px 0px;
  border: 1px solid #fff;
  outline: none;
  border-radius: 7px;
  width: 150px;
  font-size: 15px;
  text-align: center;
  margin: 0 auto;
  // vertical-align: middle;
  display: block;
  margin-bottom: 40px;
  margin-top: 25px;
  font-weight: 400;
  &:hover {
    color: #ffbb39;
    border-color: #ffbb39;
    cursor: pointer;
    opacity: 1;
  }
}

#astronaut {
  width: 43px;
  position: absolute;
  right: 20px;
  top: 210px;
  animation: spin 4.5s infinite linear;
}

@media (max-width: 600px) {
  .permission_denied {
    h1 {
      font-size: 75px;
    }
    h3 {
      font-size: 16px;
      width: 200px;
      margin: 0 auto;
      line-height: 23px;
      span {
        width: 60px;
      }
    }
  }
  #astronaut {
    width: 35px;
    right: 40px;
    top: 170px;
  }
}

.saturn,
.saturn-2,
.hover {
  animation: hover 2s infinite ease-in-out alternate;
}

@keyframes hover {
  0% {
    transform: translateY(3px);
  }
  100% {
    transform: translateY(-3px);
  }
}
