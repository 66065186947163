.card {
	box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
	&.bg-default {
		.card-body-icon {
			color: #d1d1d1;
		}
	}
	.card-body-icon {
		right: -5px;
	}
	&.inactive {
		color: #646464;
		.card-body {
			background: $wrapperBg;
		}
	}

	// Chart Card
	&.chart {
		.card-body-chart {
			font-size: 3em;
			float: left;
		}
		.card-body-value {
			float: left;
			font-size: 3em;
			padding: 0 20px;
		}
		.control {
			float: left;
			margin: 0 20px;
			input {
				left: 175px;
			}
			&:after {
				content: ' \B0 C';
				position: absolute;
				top: 58px;
				left: 143px;
				font-size: 1em;
				letter-spacing: -2px;
			}
		}
		&.warning {
			&:before {
				color: rgba(255, 255, 255, .3);
				font-family: 'Font Awesome 5 Free';
				font-size: 8em;
				font-weight: 900;
				left: calc(50% - 62px);
				position: absolute;
				top: calc(50% - 96px);
			}
			&.cold {
				&:before {
					content: '\f2dc';
				}
			}
			&.hot {
				&:before {
					content: '\f071';
				}
			}
		}
	}

	// Video feed
	&.video-feed {
		max-width: 400px;
		.rec {
			color: white;
			font-size: .8rem;
			position: absolute;
			right: 10px;
			text-transform: uppercase;
			top: 7rem;
			.dot {
				animation: pulse 1.5s ease-in infinite;
				background: $red;
				border-radius: 50%;
				display: inline-block;
				height: 12px;
				width: 12px;
			}
		}
	}

	// Weather Card
	&.weather {
		.update {
			background: none !important;
			border: none !important;
			box-shadow: none !important;
			outline: none !important;
			color: #999999;
			padding: 0.15rem 0.3rem;
			font-size: .7rem;
			&:hover,
			:focus,
			:active,
			.focus,
			.active {
				color: #474747;
			}
		}
		.date {
			border-bottom: solid 1px #ddd;
			color: #888888;
			font-size: .92em;
			margin-bottom: 20px;
			padding-bottom: 10px;
		}
		.icon {
			font-size: 3em;
		}
		.title {
			font-size: 1.5em;
			margin-bottom: 10px;
			font-weight: bold;
		}
		.sub-title {
			font-size: 1.1em;
			font-style: italic;
			color: #6d6d6d;
		}
		.temperature {
			font-size: 3em;
			line-height: .8em;
			small {
				font-size: 0.6em;
				color: #888888;
			}
		}
		.humidty,
		.temp,
		.wind {
			font-size: .8em;
		}
		&.minimal {
			box-shadow: none;
			border: none;
			padding: 0;
			.card-body {
				padding: 0;
			}
			.col-sm-6,
			.col-sm-3 {
				width: 100% !important;
				flex: none;
				max-width: 100%;
				text-align: center;
			}
			.date {
				text-transform: uppercase;
				padding-top: 10px;
				border-top: solid 1px #ddd;
				text-align: center;
				margin-bottom: 10px;
			}
			.temperature {
				font-size: 1.3em;
			}
			.description,
			.humidty,
			.temp,
			.wind {
				display: none;
			}
		}
	}
}
