#mainNav {
  .navbar-collapse {
    overflow: auto;

    max-height: 75vh;
    .navbar-nav {
      .nav-item {
        .nav-link {
          cursor: pointer;
        }
      }
    }
    .navbar-sidenav {
      .nav-link-collapse:after {
        float: right;
        font-weight: 900;
        content: '\f107';
        font-family: 'Font Awesome 5 Free';
      }
      .nav-link-collapse.collapsed:after {
        content: '\f105';
      }
      .sidenav-second-level,
      .sidenav-third-level {
        padding-left: 0;
        > li > a {
          display: block;

          padding: 0.5em 0;
          &:focus,
          &:hover {
            text-decoration: none;
          }
        }
      }
      .sidenav-second-level > li > a {
        padding-left: 1em;
      }
      .sidenav-third-level > li > a {
        padding-left: 2em;
      }
    }
    .sidenav-toggler {
      display: none;
    }
    .navbar-nav > .nav-item.dropdown {
      > .nav-link {
        position: relative;

        min-width: 45px;
        &:after {
          float: right;

          width: auto;

          content: '\f105';
          font-weight: 900;

          border: none;

          font-family: 'Font Awesome 5 Free';
        }
        .indicator {
          position: absolute;
          top: 5px;
          left: 21px;
          font-size: 10px;
          .heartbeat {
            border-radius: 70px;
            border-style: solid;
            border-width: 5px;
            height: 21px;
            position: absolute;
            right: -4px;
            top: -3px;
            width: 21px;
            z-index: 10;
          }
        }
      }
      &.show > .nav-link:after {
        content: '\f107';
      }
      .dropdown-menu > .dropdown-item > .dropdown-message {
        overflow: hidden;

        max-width: none;

        text-overflow: ellipsis;
      }
    }
  }
  @media (min-width: 992px) {
    .navbar-brand {
      width: $sidenav-base-width;
    }
    .navbar-collapse {
      overflow: visible;

      max-height: none;
      .navbar-sidenav {
        position: absolute;
        top: 0;
        left: 0;

        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;

        margin-top: $navbar-base-height;

        > .nav-item {
          width: $sidenav-base-width;
          padding: 0;
          > .nav-link {
            padding: 1em;
          }
          .sidenav-second-level,
          .sidenav-third-level {
            padding-left: 0;

            list-style: none;

            > li {
              width: $sidenav-base-width;
              > a {
                padding: 1em;
              }
            }
          }
          .sidenav-second-level > li > a {
            padding-left: 2.75em;
          }
          .sidenav-third-level > li > a {
            padding-left: 3.75em;
          }
        }
      }
      .navbar-nav > .nav-item.dropdown {
        > .nav-link {
          min-width: 0;
          &:after {
            width: 24px;

            text-align: center;
          }
        }
        .dropdown-menu {
          display: block;
          > .dropdown-item > .dropdown-message {
            max-width: 300px;
          }
        }
      }
    }
  }
}
