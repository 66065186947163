/*
  $primary:       $blue !default;
  $success:       $green !default;
  $info:          $cyan !default;
  $warning:       $yellow !default;
  $danger:        $red !default;
  $light:         $gray-100 !default;
  $dark:          $gray-800 !default;
*/

$card-cap-bg: rgba($black, .03) !default;
$card-bg: $white;

.bg-success-secondary {
	background-color: $greenMeadow !important;
}
