@keyframes pulse {
	0% {
		box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
	}
	70% {
		box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
	}
	100% {
		box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
	}
}

@keyframes heartbeat {
  0% {
    transform: scale(0);
    opacity: 0.0; }
  25% {
    transform: scale(0.1);
    opacity: 0.1; }
  50% {
    transform: scale(0.5);
    opacity: 0.3; }
  75% {
    transform: scale(0.8);
    opacity: 0.5; }
  100% {
    transform: scale(1);
		opacity: 0.0; }
}

@keyframes heartRateIn {
	0% {
		width: 100%;
	}
	50% {
		width: 0;
	}
	100% {
		width: 0;
	}
}

@keyframes heartRateOut {
	0% {
		left: -120%;
	}
	30% {
		left: -120%;
	}
	100% {
		left: 0;
	}
}

@keyframes sparkle {
  0% {
    opacity: 1.0;
  }
  100% {
    opacity: 0.3;
  }
}

@keyframes spin {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}